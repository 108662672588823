<template>
  <admin>
    <metatag title="Survey List"></metatag>
    <page-header>
      <template v-slot:action>
        <can :code="['admin', 'admin_survey']">
          <v-btn
            :block="$vuetify.breakpoint.smAndDown"
            large
            color="primary"
            exact
            :to="{ name: 'survey.create' }"
          >
            <v-icon small left>mdi-plus-circle</v-icon>
            <span v-text="'Add New'"></span>
          </v-btn>
        </can>
      </template>
    </page-header>

    <v-card>
      <can :code="['admin', 'admin_survey']">
        <toolbar-menu
          ref="toolbar"
          :bulkActionDropdown="true"
          :filterDateRange="false"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          :bulkActionDropdownItems="tabletoolbar.actionDropdownItems"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:activate="activate"
          @update:deactivate="deactivate"
          @update:delete="deleteBulk"
        ></toolbar-menu>
      </can>

      <can :code="['dealer_survey']">
        <toolbar-menu
          ref="toolbar"
          :bulkActionDropdown="false"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          :bulkActionDropdownItems="tabletoolbar.actionDropdownItems"
          @update:search="setSearch"
        ></toolbar-menu>
      </can>

      <v-card-text class="pa-0">
        <!-- Data Table -->
        <v-data-table
          :headers="resources.headers"
          :items="surveys ? surveys.data : []"
          :loading="resources.loading"
          :items-per-page="5"
          :server-items-length="resources.total"
          :options.sync="resources.options"
          color="primary"
          item-key="id"
          class="text-no-wrap"
        >
          <template v-slot:item.bulk_select="{ item }">
            <can :code="['admin', 'admin_survey']">
              <v-checkbox
                v-if="item.type === 'custom'"
                :disabled="item.type != 'custom'"
                v-model="selectedSurvey"
                :value="item.id"
                @click="previewSurveySelected"
              ></v-checkbox>
            </can>
          </template>

          <template v-slot:item.id="{ item }">
            <div class="text-no-wrap">
              <can :code="['admin', 'admin_survey']">
                <v-btn
                  :to="{ name: 'survey.edit', params: { id: item.id } }"
                  text
                  color="primary"
                  icon
                >
                  {{ item.id }}
                </v-btn>
              </can>
              <can :code="['dealer_survey']">
                <v-btn text color="primary" icon>
                  {{ item.id }}
                </v-btn>
              </can>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <v-icon v-if="item.type != 'custom'" color="success">
              mdi-circle-medium small
            </v-icon>
            <span>{{ item.name }}</span>
          </template>

          <template v-slot:item.type="{ item }">
            <v-chip label>
              {{ item.type }}
            </v-chip>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip
              v-if="item.status == true"
              class="ma-2"
              color="green"
              text-color="white"
            >
              active
            </v-chip>
            <v-chip v-else class="ma-2" color="red" text-color="white">
              inactive
            </v-chip>
          </template>

          <template v-slot:item.created_at="{ item }">
            <span>{{ formatDate(item.created_at) }}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <div class="text-no-wrap">
              <!-- Copy to clipboard -->
              <can :code="['admin', 'admin_survey']">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="copySurveyLink(item)" text icon v-on="on">
                      <v-icon small>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Copy Survey Link'"></span>
                </v-tooltip>
              </can>

              <!-- Edit -->
              <can :code="['admin', 'admin_survey']">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      icon
                      v-on="on"
                      :to="{ name: 'survey.edit', params: { id: item.id } }"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Edit'"></span>
                </v-tooltip>
              </can>

              <!-- Result -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    :to="{
                      name: 'survey.result',
                      params: { id: item.id, name: item.name },
                    }"
                  >
                    <v-icon small>mdi-view-list</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Results'"></span>
              </v-tooltip>

              <!-- CDCs -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    :to="{
                      name: 'cdc',
                      params: { id: item.id, name: item.name },
                    }"
                  >
                    <v-icon small>mdi-alert</v-icon>
                  </v-btn>
                </template>
                <span v-text="'CDCs'"></span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      tabletoolbar: {
        isSearching: false,
        search: null,
        toggleBulkEdit: false,
        bulkCount: 0,
        actionDropdownItems: [
          {
            slug: "activate",
            action: "Activate",
          },
          {
            slug: "deactivate",
            action: "Deactivate",
          },
          {
            slug: "delete",
            action: "Delete",
          },
        ],
      },
      resources: {
        total: 5,
        loading: true,
        headers: [
          {
            text: "",
            align: "center",
            value: "bulk_select",
            width: "30px",
          },
          {
            text: "ID",
            align: "center",
            value: "id",
            width: "30px",
          },
          {
            text: "Survey",
            align: "center",
            value: "name",
          },
          {
            text: "Type",
            align: "center",
            value: "type",
          },
          {
            text: "No. of questions",
            align: "center",
            value: "questions_count",
          },
          {
            text: "Status",
            align: "center",
            value: "status",
          },
          {
            text: "Date Created",
            align: "center",
            value: "created_at",
          },
          {
            text: "Actions",
            align: "center",
            value: "action",
            sortable: false,
            class: "muted--text text-no-wrap",
          },
        ],
        options: {},
        previewData: [],
        statusOptions: [],
        actionOptions: [],
      },
      selectedSurvey: [],
    };
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        const orderBy = sortBy.length ? sortBy[0] : "type";
        const sortDir = !sortDesc[0] ? "DESC" : "ASC";
        this.getItems(page, itemsPerPage, this.q, orderBy, sortDir);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      surveys: "survey/GET_SURVEY",
    }),
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/show",
      getSurveys: "survey/getSurveys",
      deleteSurveys: "survey/deleteSurveys",
      updateSurveys: "survey/updateSurveys",
    }),
    toggleBulkEdit(val) {
      if (val === false) {
        this.selectedSurvey = [];
        this.tabletoolbar.bulkCount = 0;
      }
    },
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";

      this.resources;

      this.getItems(page, itemsPerPage, e.target.value, orderBy, sortDir);
    }, 300),
    setDateRange: debounce(async function (e) {
      let startDate = e[0];
      endDate = e[1];
    }, 300),
    previewSurvey() {
      alert("Add previewing function...");
    },
    triggerBulkAction(val) {},
    previewSurveySelected() {
      if (this.selectedSurvey.length < 1) {
        this.tabletoolbar.toggleBulkEdit = false;
      } else {
        this.tabletoolbar.toggleBulkEdit = true;
      }

      this.tabletoolbar.bulkCount = this.selectedSurvey.length;
      // alert(JSON.stringify(this.selectedSurvey)
    },
    copySurveyLink(item) {
      var link = `${item.survey_url}`;
      navigator.clipboard.writeText(link);

      this.showSnackbar({
        text: "Link copied to clipboard.",
        timeout: 1000,
      });
    },
    activate() {
      const self = this;

      let payload = { ids: self.selectedSurvey, status: 1 };

      self.updateSurveys(payload).then(function (data) {
        const { sortBy, sortDesc, page, itemsPerPage } = self.resources.options;
        self.getItems(page, itemsPerPage);
        self.selectedSurvey = [];
        self.tabletoolbar.bulkCount = 0;
      });
    },
    deactivate() {
      const self = this;

      let payload = { ids: self.selectedSurvey, status: 0 };

      self.updateSurveys(payload).then(function (data) {
        const { sortBy, sortDesc, page, itemsPerPage } = self.resources.options;
        self.getItems(page, itemsPerPage);
        self.selectedSurvey = [];
        self.tabletoolbar.bulkCount = 0;
      });
    },
    deleteBulk() {
      const self = this;

      this.deleteSurveys(self.selectedSurvey).then(function (data) {
        const { sortBy, sortDesc, page, itemsPerPage } = self.resources.options;
        self.getItems(page, itemsPerPage);
        self.selectedSurvey = [];
        self.tabletoolbar.bulkCount = 0;
      });
    },
    formatDate(date) {
      return helpers.format_date(date);
    },

    getItems(
      page,
      itemsPerPage,
      q = "",
      orderBy = "created_at",
      sortDir = "DESC"
    ) {
      this.resources.loading = true;

      this.getSurveys({
        page: page,
        per_page: itemsPerPage,
        q: q,
        order_by: orderBy,
        sort: sortDir,
      }).then((data) => {
        this.resources.loading = false;
        this.resources.total = this.surveys.meta.total;
      });
    },
  },
  mounted() {},
};
</script>

<style></style>
