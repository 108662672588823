var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Survey List"}}),_c('page-header',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('can',{attrs:{"code":['admin', 'admin_survey']}},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"primary","exact":"","to":{ name: 'survey.create' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus-circle")]),_c('span',{domProps:{"textContent":_vm._s('Add New')}})],1)],1)]},proxy:true}])}),_c('v-card',[_c('can',{attrs:{"code":['admin', 'admin_survey']}},[_c('toolbar-menu',{ref:"toolbar",attrs:{"bulkActionDropdown":true,"filterDateRange":false,"isSearchColsAuto":true,"items":_vm.tabletoolbar,"bulkActionDropdownItems":_vm.tabletoolbar.actionDropdownItems},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch,"update:daterangesurvey":_vm.setDateRange,"update:activate":_vm.activate,"update:deactivate":_vm.deactivate,"update:delete":_vm.deleteBulk}})],1),_c('can',{attrs:{"code":['dealer_survey']}},[_c('toolbar-menu',{ref:"toolbar",attrs:{"bulkActionDropdown":false,"isSearchColsAuto":true,"items":_vm.tabletoolbar,"bulkActionDropdownItems":_vm.tabletoolbar.actionDropdownItems},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch}})],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.resources.headers,"items":_vm.surveys ? _vm.surveys.data : [],"loading":_vm.resources.loading,"items-per-page":5,"server-items-length":_vm.resources.total,"options":_vm.resources.options,"color":"primary","item-key":"id"},on:{"update:options":function($event){return _vm.$set(_vm.resources, "options", $event)}},scopedSlots:_vm._u([{key:"item.bulk_select",fn:function(ref){
var item = ref.item;
return [_c('can',{attrs:{"code":['admin', 'admin_survey']}},[(item.type === 'custom')?_c('v-checkbox',{attrs:{"disabled":item.type != 'custom',"value":item.id},on:{"click":_vm.previewSurveySelected},model:{value:(_vm.selectedSurvey),callback:function ($$v) {_vm.selectedSurvey=$$v},expression:"selectedSurvey"}}):_vm._e()],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('can',{attrs:{"code":['admin', 'admin_survey']}},[_c('v-btn',{attrs:{"to":{ name: 'survey.edit', params: { id: item.id } },"text":"","color":"primary","icon":""}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('can',{attrs:{"code":['dealer_survey']}},[_c('v-btn',{attrs:{"text":"","color":"primary","icon":""}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.type != 'custom')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-circle-medium small ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == true)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" active ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" inactive ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('can',{attrs:{"code":['admin', 'admin_survey']}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.copySurveyLink(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-text")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Copy Survey Link')}})])],1),_c('can',{attrs:{"code":['admin', 'admin_survey']}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","to":{ name: 'survey.edit', params: { id: item.id } }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Edit')}})])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","to":{
                    name: 'survey.result',
                    params: { id: item.id, name: item.name },
                  }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-view-list")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Results')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","to":{
                    name: 'cdc',
                    params: { id: item.id, name: item.name },
                  }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('CDCs')}})])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }